import React from "react";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // You can use any icon you prefer

const SidebarButton = ({ toggleSidebar }) => {
  return (
    <IconButton
      onClick={toggleSidebar}
      sx={{
        backgroundColor: "#007bff", // Default background color
        width: 40,
        height: 40,
        color: "#fff",
        "&:hover": {
          backgroundColor: "#3a86ff", // Blue-light hover effect
        },
      }}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default SidebarButton;
