import React, { useRef, useState } from "react";
import { DataTableProducts } from "../DataTable/DatatableProducts";
import { useDispatch } from "react-redux";
import { addOrUpdateProducts } from "../redux/productSlice";
import ModifyProductDialog from "../dialog/ModifyDialog";
import { SnackbarProvider, useSnackbar } from "notistack";

const ProductForm = ({ products, categories, onDelete }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const defaultState = {
    id: "",
    name: "",
    description: "",
    price: null,
    sector: "",
    image: null,
  };

  const [productsData, setProductData] = useState(defaultState);
  const [isEditing, setIsEditing] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [openModifyDialog, setOpenModifyDialog] = useState(false);
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "sector") {
      const selectedSector = categories.find(
        (category) => category._id === value
      );
      setProductData((prevData) => ({ ...prevData, [name]: selectedSector }));
    } else {
      setProductData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProductData({ ...productsData, image: file });

    const reader = new FileReader();
    reader.onloadend = () => setImagePreview(reader.result);
    if (file) reader.readAsDataURL(file);
  };

  const handleSubmitProducts = async (e) => {
    e.preventDefault();

    let missingFields = [];

    if (!productsData.image) {
      missingFields.push("Image");
    }
    if (!productsData.sector || !productsData.sector._id) {
      missingFields.push("Sector");
    }

    if (missingFields.length > 0) {
      enqueueSnackbar(
        `Nuk ka data për tu dërguar, mungon: ${missingFields.join(", ")}`,
        { variant: "error" }
      );
      return;
    }

    try {
      const response = await dispatch(
        addOrUpdateProducts({ productsData, isEditing })
      );

      if (response.payload.success) {
        enqueueSnackbar("U krye me sukses!", { variant: "success" });
        resetForm();
        handleCloseModifyDialog();
      } else {
        enqueueSnackbar("Ka ndodhur një gabim! Provoni përsëri.", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("Ndodhi një gabim në dërgimin e të dhënave!", {
        variant: "error",
      });
      console.error("Notistack error:", err);
    }
  };

  const resetForm = () => {
    setProductData(defaultState);
    setImagePreview(null);
    setIsEditing(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleOpenPostDialog = () => {
    setOpenModifyDialog(true);
    setIsEditing(true);
    resetForm();
  };

  const onModify = (product) => {
    setOpenModifyDialog(true);
    setProductData({ ...product });
    setIsEditing(true);

    if (product.image) {
      setImagePreview(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/${product.image}`
      );
    }
  };

  const handleCloseModifyDialog = () => {
    setOpenModifyDialog(false);
    resetForm();
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <button
        onClick={handleOpenPostDialog}
        style={{
          position: "fixed",
          left: "50%",
          bottom: "20px",
          transform: "translateX(-50%)",
          width: "80%",
          padding: "10px",
          background: "#285fa7",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          zIndex: 100,
        }}
      >
        Shto Produkt
      </button>

      <ModifyProductDialog
        openDialog={openModifyDialog}
        handleCloseDialog={handleCloseModifyDialog}
        handleSubmitProducts={handleSubmitProducts}
        handleInputChange={handleInputChange}
        handleFileChange={handleFileChange}
        imagePreview={imagePreview}
        productsData={productsData}
        categories={categories}
        isEditing={isEditing}
        fileInputRef={fileInputRef}
      />

      <DataTableProducts
        data={products}
        onModify={onModify}
        onDelete={onDelete}
      />
    </div>
  );
};

const ProductFormWithProvider = (props) => (
  <SnackbarProvider maxSnack={2}>
    <ProductForm {...props} />
  </SnackbarProvider>
);

export { ProductFormWithProvider as ProductForm };
