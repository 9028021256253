import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Register from "./auth/Register";
import Login from "./auth/Login";
import "./App.css";
import { AdminPage } from "./Admin/AdminPage";
import ProtectedRoute from "./Context/ProtectedRoute";
// import FAQASTATIKE from "./FAQASTATIKE";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Universal Home Component for all users */}
          {/* <Route path="/" element={<FAQASTATIKE />} /> */}
          <Route path="/" element={<Home />} />

          {/* Register page */}
          <Route path="/register" element={<Register />} />

          {/* Login page */}
          <Route path="/login" element={<Login />} />

          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
