import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/sectors`;

// Thunks for CRUD operations
export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(API_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch categories"
      );
    }
  }
);

export const addOrUpdateCategory = createAsyncThunk(
  "categories/addOrUpdateCategory",
  async ({ categoryData, isEditing }, { dispatch, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("name", categoryData.name);
      formData.append("nrRendor", categoryData.nrRendor);
      formData.append("image", categoryData.image);

      // Make the API request to add or update the category
      const response = isEditing
        ? await axios.put(`${API_URL}/${categoryData._id}`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
        : await axios.post(API_URL, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });

      // After the update or creation, re-fetch the categories to update the state
      await dispatch(fetchCategories());

      return response.data; // Optionally return the response if needed
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || "Failed to save category");
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Re-fetch categories after deletion
      await dispatch(fetchCategories());

      return id; // Return the deleted category's ID
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to delete category"
      );
    }
  }
);

// Category slice
const apiSlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addOrUpdateCategory.fulfilled, (state, action) => {
        // Since we now re-fetch the categories in addOrUpdateCategory, we don't need to manually update the state here.
        // The state will be updated automatically by the fetchCategories call.
        console.log("Category added or updated, list re-fetched.");
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        // After deletion, the categories list is re-fetched, so no need to update the state here.
        console.log("Category deleted, list re-fetched.");
      });
  },
});

export default apiSlice.reducer;
