import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { addOrUpdateCategory } from "../redux/apiSlice";
import { DataTableCategory } from "../DataTable/DatatableCategory";
import ModifyProductDialog from "../dialog/ModifyDialog";
import { SnackbarProvider, useSnackbar } from "notistack";

const CategoryForm = ({ categories, onDelete }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const defaultStateCategory = {
    id: "",
    name: "",
    nrRendor: "",
    image: null,
  };

  const [categoryData, setCategoryData] = useState(defaultStateCategory);
  const [isEditing, setIsEditing] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [openModifyDialog, setOpenModifyDialog] = useState(false);
  const fileInputRefCategory = useRef(null);

  const handleInputChangeCategory = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };

  const handleFileChangeCategory = (e) => {
    const file = e.target.files[0];
    setCategoryData({ ...categoryData, image: file });

    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => setImagePreview(reader.result);
    if (file) reader.readAsDataURL(file);
  };

  const handleSubmitCategory = async (e) => {
    e.preventDefault();

    // Ensure required fields (name, nrRendor, image) are provided
    let missingFields = [];
    if (!categoryData.name) missingFields.push("Name");
    if (!categoryData.nrRendor) missingFields.push("Nr. Rendor");
    if (!categoryData.image) missingFields.push("Image");

    // If there are missing fields, show error message and prevent submission
    if (missingFields.length > 0) {
      enqueueSnackbar(
        `Nuk ka data për tu dërguar, mungon: ${missingFields.join(", ")}`,
        { variant: "error" }
      );
      return;
    }

    // Proceed with dispatch if no missing fields
    try {
      const response = await dispatch(
        addOrUpdateCategory({ categoryData, isEditing })
      );
      if (response.payload.success) {
        enqueueSnackbar("U krye me sukses!", { variant: "success" });
        resetForm();
        setOpenModifyDialog(false); // Close the dialog on success
      } else {
        enqueueSnackbar("Ka ndodhur një gabim! Provoni përsëri.", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("Ndodhi një gabim në dërgimin e të dhënave!", {
        variant: "error",
      });
      console.error("Notistack error:", err);
    }
  };

  const resetForm = () => {
    setCategoryData(defaultStateCategory);
    setImagePreview(null);
    setIsEditing(false);
    if (fileInputRefCategory.current) {
      fileInputRefCategory.current.value = "";
    }
  };

  const handleOpenPostDialog = () => {
    resetForm();
    // Find the maximum nrRendor in the existing categories
    const maxNrRendor = categories.length
      ? Math.max(...categories.map((category) => category.nrRendor || 0))
      : 0;

    // Set the initial data for the new category
    setCategoryData({
      ...defaultStateCategory,
      nrRendor: maxNrRendor + 1, // Increment the highest number by 1
    });

    setOpenModifyDialog(true);
    setIsEditing(false); // Ensure this is treated as a new addition
  };

  const onModify = (category) => {
    setOpenModifyDialog(true);
    setCategoryData({ ...category });
    setIsEditing(true);

    if (category.image) {
      setImagePreview(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/${category.image}`
      );
    }
  };

  const handleCloseModifyDialog = () => {
    setOpenModifyDialog(false);
    resetForm();
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <button
        onClick={handleOpenPostDialog}
        style={{
          position: "fixed",
          left: "50%",
          bottom: "20px",
          transform: "translateX(-50%)",
          width: "80%",
          padding: "10px",
          background: "#285fa7",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          zIndex: 100,
        }}
      >
        Shto Kategori
      </button>

      <ModifyProductDialog
        openDialog={openModifyDialog}
        handleCloseDialog={handleCloseModifyDialog}
        handleSubmitProducts={handleSubmitCategory}
        handleInputChange={handleInputChangeCategory}
        handleFileChange={handleFileChangeCategory}
        imagePreview={imagePreview}
        productsData={categoryData}
        fromCategory={true}
        categories={categories}
        isEditing={isEditing}
        fileInputRef={fileInputRefCategory}
      />

      <DataTableCategory
        data={categories}
        onModify={onModify}
        onDelete={onDelete}
      />
    </div>
  );
};

const CategoryFormWithProvider = (props) => (
  <SnackbarProvider maxSnack={2}>
    <CategoryForm {...props} />
  </SnackbarProvider>
);

export { CategoryFormWithProvider as CategoryForm };
