import React from "react";

const DeleteConfirmationDialog = ({ product, onConfirm, onCancel }) => {
  return (
    <div style={overlayStyle}>
      <div style={dialogStyle}>
        <p>Deshironi ta fshini {product.name}?</p>
        <div style={buttonContainerStyle}>
          <button
            onClick={() => onConfirm(product)}
            style={buttonStyle("danger")}
          >
            Po
          </button>
          <button onClick={onCancel} style={buttonStyle("primary")}>
            Jo
          </button>
        </div>
      </div>
    </div>
  );
};

const overlayStyle = {
  position: "fixed", // This ensures the dialog is fixed on the screen
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background to dim the rest of the page
  display: "flex",
  justifyContent: "center", // Centers the dialog horizontally
  alignItems: "center", // Centers the dialog vertically
  zIndex: 1000, // Ensure the dialog appears above other content
};

const dialogStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  width: "300px", // You can adjust the width as per your requirement
  textAlign: "center", // Centers the text inside the dialog
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center", // Centers the buttons horizontally
  gap: "10px",
  marginTop: "10px",
};

const buttonStyle = (type) => ({
  padding: "10px 20px",
  background: type === "danger" ? "#dc3545" : "#007BFF",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
});

export default DeleteConfirmationDialog;
