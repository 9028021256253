import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  Stack,
  Card,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";

const CardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
}));

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/register`,
        {
          name,
          email,
          password,
        }
      );

      if (response.data.token) {
        // Save the token to localStorage
        localStorage.setItem("token", response.data.token);

        // Redirect to the login page or Admin dashboard
        navigate("/admin");
      }
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "Registration failed");
    }
  };
  // Logic to enable/disable Register button based on the code
  const isRegisterDisabled = code !== "666";
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Stack direction="column" justifyContent="center" alignItems="center">
        <CardContainer>
          <Typography component="h1" variant="h4" align="center">
            Register
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <TextField
                required
                fullWidth
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                required
                fullWidth
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                required
                fullWidth
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            {/* Code input field */}
            <FormControl fullWidth margin="normal">
              <FormLabel>Code:</FormLabel>
              <TextField
                required
                fullWidth
                id="Code"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={isRegisterDisabled} // Disable button if code is not 666
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "darkgray",
                },
              }}
            >
              Register
            </Button>
          </Box>
          {error && (
            <Typography color="error" align="center">
              {error}
            </Typography>
          )}
        </CardContainer>
      </Stack>
    </div>
  );
};

export default Register;
