import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import {
  Logout as LogoutIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { logout } from "../redux/authSlice"; // Redux action for logout
import { selectUser } from "../redux/authSlice";
import SidebarButton from "../Sidebar/SidebarButton";

const Header = ({ activeButton, toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Access user from Redux state
  const user = useSelector(selectUser); // Correctly access user from the auth slice

  const [anchorEl, setAnchorEl] = useState(null); // Menu anchor state
  const openMenu = Boolean(anchorEl); // Check if menu is open

  // Open the menu when the profile icon is clicked
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Handle logout action
  const handleLogout = () => {
    dispatch(logout()); // Call logout action from Redux
    localStorage.removeItem("token"); // Remove token from localStorage
    navigate("/login"); // Redirect to login page
  };
  if (!user) {
    return <div>Loading...</div>; // Ensure the user data is available
  }

  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        marginBottom: "10px",
        background: "#333",
        color: "#fff",
      }}
    >
      <SidebarButton toggleSidebar={toggleSidebar} />

      {/* Title */}
      <Typography
        variant="h6"
        sx={{ flex: 1, textAlign: "center", fontWeight: "bold" }}
      >
        {activeButton === "Kategorite" ? "Kategorit" : "Produktet"}
      </Typography>

      {/* Profile and User Info */}
      {user && (
        <Box>
          <IconButton onClick={handleProfileClick} sx={{ color: "#fff" }}>
            <Avatar
              sx={{
                backgroundColor: "#007bff",
                width: 40,
                height: 40,
                "&:hover": {
                  backgroundColor: "#3a86ff", // Blue-light hover effect
                },
              }}
            >
              <PersonIcon />
            </Avatar>
          </IconButton>

          {/* Profile menu */}
          <Menu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
            <MenuItem disabled>
              <Typography variant="body1">{user.name}</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout} sx={{ color: "#dc3545" }}>
              <LogoutIcon sx={{ marginRight: "10px" }} /> Dil (LogOut)
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default Header;
