import React from "react";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";

const Sidebar = ({ open, onClose, onButtonClick }) => {
  const handleItemClick = (text) => {
    onButtonClick(text); // Trigger the button click action
    onClose(); // Close the sidebar after the button is clicked
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        width: "250px",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "250px",
          backgroundColor: "#333",
          color: "#fff",
        },
        position: "fixed", // Fixed positioning to prevent content displacement
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 1000, // Make sure it stays on top of other content
      }}
    >
      <List>
        <ListItem
          button
          onClick={() => handleItemClick("Kategorite")}
          sx={{
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#3a86ff", // Blue-light color
            },
          }}
        >
          <ListItemText primary="Kategorite" />
        </ListItem>
        <ListItem
          button
          onClick={() => handleItemClick("Lista e produkteve")}
          sx={{
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#3a86ff", // Blue-light color
            },
          }}
        >
          <ListItemText primary="Lista e produkteve" />
        </ListItem>
        <ListItem
          button
          onClick={() => handleItemClick("Shop")}
          sx={{
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#3a86ff", // Blue-light color
            },
          }}
        >
          <ListItemText primary="Shop" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
