const DataTableCategory = ({ data = [], onModify, onDelete }) => (
  <table style={{ width: "100%", borderCollapse: "collapse" }}>
    <thead>
      <tr>
        <th style={tableHeaderStyle}>Foto</th>
        <th style={tableHeaderStyle}>Emri</th>
        <th style={tableHeaderStyle}>Nr.Rendor</th>
        <th style={tableHeaderStyle}>Funksioni</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item) => (
        <tr key={item._id}>
          <td style={tableCellStyle}>
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${item.image}`}
              alt={item.name}
              width="50"
            />
          </td>
          <td style={tableCellStyle}>{item.name}</td>
          <td style={tableCellStyle}>{item.nrRendor}</td>
          <td style={tableCellStyle}>
            <button onClick={() => onModify(item)} style={modifyButtonStyle}>
              Modifiko
            </button>
            <button onClick={() => onDelete(item)} style={deleteButtonStyle}>
              Fshije
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const tableHeaderStyle = {
  border: "1px solid #ccc",
  padding: "10px",
};

const tableCellStyle = {
  border: "1px solid #ccc",
  padding: "10px",
};

const modifyButtonStyle = {
  marginRight: "5px",
  padding: "5px",
  background: "#ffc107",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

const deleteButtonStyle = {
  padding: "5px",
  background: "#dc3545",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

export { DataTableCategory };
