import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import SelectField from "../Select/Select";

const ModifyProductDialog = ({
  openDialog,
  fromCategory,
  handleCloseDialog,
  handleSubmitProducts,
  handleInputChange,
  handleFileChange,
  imagePreview,
  productsData,
  categories,
  isEditing,
  fileInputRef,
}) => {
  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>
        {isEditing
          ? !fromCategory
            ? "Modifiko Produktin"
            : "Modifiko Kategorinë"
          : !fromCategory
          ? "Shtoni Produkt"
          : "Shtoni Kategorinë"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmitProducts} style={{ marginBottom: "20px" }}>
          <input
            type="text"
            name="name"
            value={productsData.name}
            onChange={handleInputChange}
            placeholder={!fromCategory ? "Emri Produktit" : "Emri Kategorise"}
            required
            style={inputStyle}
          />
          {!fromCategory && (
            <input
              type="number"
              name="price"
              value={productsData.price}
              onChange={handleInputChange}
              placeholder="Cmimi Produktit"
              style={inputStyle}
            />
          )}
          {fromCategory && (
            <input
              type="number"
              name="nrRendor"
              value={productsData.nrRendor}
              onChange={handleInputChange}
              placeholder="Numri rendor në menu"
              required
              style={inputStyle}
            />
          )}
          {!fromCategory && (
            <SelectField
              name="sector"
              value={productsData.sector?._id || ""}
              options={categories}
              onChange={handleInputChange}
              placeholder="Zgjidh Kategorinë"
              required
              style={inputStyle}
            />
          )}
          {!fromCategory && (
            <input
              type="text"
              name="description"
              value={productsData.description}
              onChange={handleInputChange}
              placeholder="Pershkrimi"
              style={{ ...inputStyle, height: "60px" }}
            />
          )}
          <input
            type="file"
            required
            accept="image/*"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ marginBottom: "10px" }}
          />
          {imagePreview && (
            <div style={{ marginBottom: "10px" }}>
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  borderRadius: "4px",
                }}
              />
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="secondary">
          Anullim
        </Button>
        <Button
          type="submit"
          onClick={(e) => {
            handleSubmitProducts(e); // Submit the product
            // handleCloseDialog(); // Close the dialog after submit
          }}
          color="primary"
        >
          {isEditing
            ? !fromCategory
              ? "Modifiko Produktin"
              : "Modifiko Kategorinë"
            : !fromCategory
            ? "Shtoni Produkt"
            : "Shtoni Kategorinë"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const inputStyle = {
  padding: "10px",
  marginBottom: "10px",
  width: "100%",
  boxSizing: "border-box",
  border: "1px solid #ccc",
  borderRadius: "4px",
};

export default ModifyProductDialog;
