// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "./apiSlice";
import productSlice from "./productSlice";
import authSlice, { logout } from "./authSlice";
import { jwtDecode } from "jwt-decode";

// Persist state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch (e) {
    console.error("Could not save state:", e);
  }
};

// Load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (!serializedState) return undefined;

    const state = JSON.parse(serializedState);
    const { auth } = state;

    // Validate token
    if (auth.token) {
      const decodedToken = jwtDecode(auth.token);
      if (decodedToken.exp * 1000 < Date.now()) {
        console.warn("Token has expired. Clearing state.");
        localStorage.removeItem("reduxState");
        return undefined; // Token expired, reset state
      }
    }
    return state;
  } catch (e) {
    console.error("Could not load state:", e);
    return undefined;
  }
};

// Initialize persisted state
const persistedState = loadState();

// Create the Redux store
export const store = configureStore({
  reducer: {
    categories: apiSlice,
    products: productSlice,
    auth: authSlice,
  },
  preloadedState: persistedState, // Rehydrate state on initialization
});

// Subscribe to store changes to save state
store.subscribe(() => {
  const state = store.getState();
  saveState(state); // Persist state

  // Token expiration check
  const { auth } = state;
  if (auth.token) {
    const decodedToken = jwtDecode(auth.token);
    if (decodedToken.exp * 1000 < Date.now()) {
      console.warn("Token has expired. Logging out.");
      store.dispatch(logout());
    }
  }
});
