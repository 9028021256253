import React from "react";

const SelectField = ({
  name,
  value,
  options = [],
  onChange,
  placeholder = "Select an option",
  required = false,
  style = {},
}) => {
  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      style={style}
    >
      <option disabled value="">
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={option._id} value={option._id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default SelectField;
