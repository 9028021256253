import React, { useState, useEffect } from "react";
import { AdminContainer } from "./AdminContainer";
import Header from "../Header/Header";
import { ProductForm } from "../Product/Product";
import { CategoryForm } from "../Category/Category";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmationDialog from "../dialog/ConfirmationDelete";
import { deleteProducts, fetchProducts } from "../redux/productSlice";
import { deleteCategory, fetchCategories } from "../redux/apiSlice";
import { CompanyForm } from "../Company/CompanyForm";
import Sidebar from "../Sidebar/Sidebar";
import { SnackbarProvider, useSnackbar } from "notistack";

const AdminPage = () => {
  const dispatch = useDispatch();
  const { categories, loading, error } = useSelector(
    (state) => state.categories
  );
  const { enqueueSnackbar } = useSnackbar();
  const { products } = useSelector((state) => state.products);

  const [sidebarOpen, setSidebarOpen] = useState(false); // State to control sidebar visibility
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [activeButton, setActiveButton] = useState("Kategorite");
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    if (!categories || categories.length === 0) {
      dispatch(fetchCategories());
    }
    if (!products || products.length === 0) {
      dispatch(fetchProducts());
    }
  }, [dispatch, categories, products]);

  const handleButtonClick = (value) => {
    setActiveButton(value);
  };

  const onDelete = (item) => {
    setDeleteConfirmation(true);
    setCurrentItem(item);
  };

  const handleDelete = async (e) => {
    const id = e._id;
    try {
      let response;
      if (e.nrRendor) {
        response = await dispatch(deleteCategory(id));
        if (response.type === "categories/deleteCategory/fulfilled") {
          enqueueSnackbar("Kategoria u fshi me sukses!", {
            variant: "success",
          });
          setDeleteConfirmation(false);
        } else {
          enqueueSnackbar(
            response.payload?.message || "Gabim gjatë fshirjes së kategorisë.",
            {
              variant: "error",
            }
          );
        }
      } else {
        response = await dispatch(deleteProducts(id));
        if (response.type === "products/deleteProducts/fulfilled") {
          enqueueSnackbar("Produkti u fshi me sukses!", {
            variant: "success",
          });
          setDeleteConfirmation(false);
        } else {
          enqueueSnackbar(
            response.payload?.message || "Gabim gjatë fshirjes së kategorisë.",
            {
              variant: "error",
            }
          );
        }
      }
    } catch (error) {
      enqueueSnackbar(
        error.message || "Diçka shkoi keq. Ju lutemi provoni përsëri.",
        {
          variant: "error",
        }
      );
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmation(null);
  };

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  return (
    <AdminContainer style={adminContainerStyle}>
      <Sidebar
        open={sidebarOpen}
        onClose={toggleSidebar}
        onButtonClick={handleButtonClick}
      />

      <Header activeButton={activeButton} toggleSidebar={toggleSidebar} />
      {activeButton === "Lista e produkteve" && (
        <ProductForm
          products={products?.products || []}
          categories={categories?.sectors || []}
          loading={loading}
          error={error}
          onDelete={onDelete}
        />
      )}
      {activeButton === "Kategorite" && (
        <CategoryForm
          categories={categories?.sectors || []}
          loading={loading}
          error={error}
          onDelete={onDelete}
        />
      )}
      {activeButton === "Shop" && <CompanyForm />}
      {deleteConfirmation && (
        <DeleteConfirmationDialog
          product={currentItem}
          onConfirm={handleDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </AdminContainer>
  );
};

const adminContainerStyle = {
  padding: "20px",
  maxWidth: "100%",
  margin: "auto",
  flex: 1,
};

const AdminPageWithProvider = (props) => (
  <SnackbarProvider maxSnack={2}>
    <AdminPage {...props} />
  </SnackbarProvider>
);

export { AdminPageWithProvider as AdminPage };
