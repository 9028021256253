import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  Stack,
  Card,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/authSlice"; // import the action
import { fetchCategories } from "../redux/apiSlice";
import { fetchProducts } from "../redux/productSlice";

const CardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
}));

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateInputs = () => {
    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateInputs()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/login`,
          {
            email,
            password,
          }
        );

        if (response.status === 200) {
          const { token, user } = response.data; // Now includes both token and user data

          if (token) {
            localStorage.setItem("token", token);

            // Dispatch the setUser action with the user data
            dispatch(setUser({ user, token }));

            // Additional actions
            dispatch(fetchCategories());
            dispatch(fetchProducts());
            navigate("/admin");
          } else {
            console.error("Token missing from the response");
          }
        }
      } catch (error) {
        console.error("Login failed", error);
        alert("Invalid credentials, please try again.");
      }
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Stack direction="column" justifyContent="center" alignItems="center">
        <CardContainer>
          <Typography component="h1" variant="h4" align="center">
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                required
                fullWidth
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                helperText={emailErrorMessage}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                required
                fullWidth
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError}
                helperText={passwordErrorMessage}
              />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "darkgray", // Change hover color to dark gray for better UX
                },
              }}
            >
              Login
            </Button>
          </Box>
        </CardContainer>
      </Stack>
    </div>
  );
};

export default LoginPage;
