import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Company.css";
import { SnackbarProvider, useSnackbar } from "notistack";

const CompanyForm = () => {
  const [companyData, setCompanyData] = useState({
    id: "",
    name: "",
    title: "",
    instagram: "",
    phone: "",
    orariStart: "",
    orariEnd: "",
    location: "",
    image: null,
    banner: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [imagePreview, setImagePreview] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const bannerInputRef = useRef(null);

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/company`
        );
        if (response.data.companies.length > 0) {
          const company = response.data.companies[0];
          setCompanyData(company);

          if (company.image) {
            setImagePreview(
              `${process.env.REACT_APP_BACKEND_URL}/uploads/${company.image}`
            );
          }
          if (company.banner) {
            setBannerPreview(
              `${process.env.REACT_APP_BACKEND_URL}/uploads/${company.banner}`
            );
          }
        }
      } catch (err) {
        setError("Error fetching company data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCompany();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.startsWith("image/")) {
      setCompanyData((prevData) => ({ ...prevData, image: file }));

      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
    }
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setCompanyData((prevData) => ({ ...prevData, banner: file }));

      const reader = new FileReader();
      reader.onloadend = () => setBannerPreview(reader.result);
      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
    }
  };

  const handleSubmitCompany = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", companyData.name);
    formData.append("title", companyData.title);
    formData.append("instagram", companyData.instagram); // Add Instagram
    formData.append("phone", companyData.phone); // Add Phone
    formData.append("orariStart", companyData.orariStart); // Add orari
    formData.append("orariEnd", companyData.orariEnd); // Add orari
    formData.append("location", companyData.location); // Add Location
    if (companyData.image) formData.append("image", companyData.image);
    if (companyData.banner) formData.append("banner", companyData.banner);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/${companyData._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setCompanyData(response.data.company);
        enqueueSnackbar("U ndryshua me sukses!", { variant: "success" });
      } else {
        enqueueSnackbar("Error per te ndryshuar te dhenat.", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar(`Error per te ndryshuar te dhenat ${err}`, {
        variant: "error",
      });
    }
  };

  return (
    <div className="company-form-container">
      <form onSubmit={handleSubmitCompany} className="company-form">
        <label>Emri Kompanis</label>
        <input
          type="text"
          name="name"
          value={companyData.name}
          onChange={handleInputChange}
          placeholder="Emri Kompanis"
        />
        <label>Titulli Kompanis</label>
        <input
          type="text"
          name="title"
          value={companyData.title}
          onChange={handleInputChange}
          placeholder="Titulli Kompanis"
        />
        <label>Instagram</label>
        <input
          type="text"
          name="instagram"
          value={companyData.instagram}
          onChange={handleInputChange}
          placeholder="Instagram URL"
        />
        <label>Phone</label>
        <input
          type="text"
          name="phone"
          value={companyData.phone}
          onChange={handleInputChange}
          placeholder="Phone Number"
        />{" "}
        <label>Orari</label>
        <div style={{ display: "flex", gap: "10px" }}>
          <input
            type="time"
            name="orariStart"
            value={companyData.orariStart}
            onChange={handleInputChange}
            placeholder="From"
          />
          <span>-</span>
          <input
            type="time"
            name="orariEnd"
            value={companyData.orariEnd}
            onChange={handleInputChange}
            placeholder="To"
          />
        </div>
        <label>Location</label>
        <input
          type="text"
          name="location"
          value={companyData.location}
          onChange={handleInputChange}
          placeholder="Location"
        />
        <div className="image-upload">
          {imagePreview && (
            <div className="image-preview-container">
              <h3>Profile Image</h3>
              <img
                src={imagePreview}
                alt="Profile Preview"
                className="small-image"
              />
            </div>
          )}
          <label htmlFor="image">Profile Upload New</label>
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
          />
        </div>
        <div className="image-upload">
          {bannerPreview && (
            <div className="image-preview-container">
              <h3>Banner Image</h3>
              <img
                src={bannerPreview}
                alt="Banner Preview"
                className="small-image"
              />
            </div>
          )}
          <label htmlFor="banner">Banner Upload New</label>
          <input
            type="file"
            name="banner"
            accept="image/*"
            onChange={handleBannerChange}
            ref={bannerInputRef}
          />
        </div>
        <button
          type="submit"
          style={{
            position: "fixed", // Change from absolute to fixed to make it sticky on scroll
            left: "50%",
            bottom: "20px", // Adjust as needed
            transform: "translateX(-50%)",
            width: "80%",
            padding: "10px",
            background: "#285fa7",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            zIndex: 100, // Ensure it's above other content
          }}
        >
          Save
        </button>
      </form>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
    </div>
  );
};

const CompanyFormWithProvider = (props) => (
  <SnackbarProvider maxSnack={2}>
    <CompanyForm {...props} />
  </SnackbarProvider>
);

export { CompanyFormWithProvider as CompanyForm };
