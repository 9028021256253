import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/products`;

// Thunks for CRUD operations
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(API_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch products"
      );
    }
  }
);

export const addOrUpdateProducts = createAsyncThunk(
  "products/addOrUpdateProducts",
  async ({ productsData, isEditing }, { dispatch, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("name", productsData.name);
      formData.append("description", productsData.description);
      formData.append("price", productsData.price);
      formData.append("sector", productsData.sector._id);
      formData.append("image", productsData.image);

      // Make the API request to add or update the product
      const response = isEditing
        ? await axios.put(`${API_URL}/${productsData._id}`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
        : await axios.post(API_URL, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });

      // After adding or updating, re-fetch the products list
      await dispatch(fetchProducts());

      return response.data; // Optionally return the response if needed
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to save product");
    }
  }
);

export const deleteProducts = createAsyncThunk(
  "products/deleteProducts",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // After deletion, re-fetch the products list
      await dispatch(fetchProducts());

      return id; // Return the deleted product's ID
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to delete product"
      );
    }
  }
);

// Product slice
const productSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addOrUpdateProducts.fulfilled, (state, action) => {
        // The list will be re-fetched after an add or update, so no need to update manually
        console.log("Product added or updated, list re-fetched.");
      })
      .addCase(deleteProducts.fulfilled, (state, action) => {
        // The list will be re-fetched after deletion, so no need to update manually
        console.log("Product deleted, list re-fetched.");
      });
  },
});

export default productSlice.reducer;
