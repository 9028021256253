const AdminContainer = ({ children }) => (
  <div
    className="admin-container"
    // style={{ display: "flex", flexWrap: "wrap" }}
  >
    {children}
  </div>
);

export { AdminContainer };
