import React, { useState } from "react";

const DataTableProducts = ({
  data = [],
  onModify,
  onDelete,
  deleteConfirmation,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.sector?.name &&
        item.sector.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Group data by sector
  const groupedBySector = filteredData.reduce((acc, item) => {
    const sectorName = item.sector?.name || "S'ka kategori";
    if (!acc[sectorName]) {
      acc[sectorName] = [];
    }
    acc[sectorName].push(item);
    return acc;
  }, {});

  return (
    <div style={{ width: "100%" }}>
      <input
        className="search-mobile"
        type="text"
        placeholder="Search products or sectors"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "20px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          boxSizing: "border-box",
        }}
      />
      {/* Table for Desktop */}
      <div className="desktop-table">
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead className="product-table">
            <tr>
              <th>Foto</th>
              <th>Emri</th>
              <th>Kategoria</th>
              <th>Cmimi</th>
              <th>Funksioni</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td style={tableHeaderStyle}>
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${item.image}`}
                    alt={item.name}
                    width="50"
                  />
                </td>
                <td style={tableCellStyle}>{item.name}</td>
                <td style={tableCellStyle}>
                  {item.sector?.name ? item.sector.name : "S'ka kategori"}
                </td>
                <td style={tableCellStyle}>
                  {item.price ? `${item.price} Lek` : null}
                </td>
                <td style={tableCellStyle}>
                  <button
                    onClick={() => onModify(item)}
                    style={modifyButtonStyle}
                  >
                    Modifiko
                  </button>
                  <button
                    onClick={() => onDelete(item)}
                    style={deleteButtonStyle}
                  >
                    Fshije
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Card Layout for Mobile */}
      <div className="mobile-cards">
        {Object.keys(groupedBySector).map((sector) => (
          <div key={sector} style={{ marginBottom: "30px" }}>
            <h3>{sector}</h3>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              {groupedBySector[sector].map((item) => (
                <div
                  key={item._id}
                  style={{
                    width: "100%", // Full width for cards
                    borderRadius: "8px",
                    boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                    padding: "15px",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${item.image}`}
                    alt={item.name}
                    width="100"
                    style={{ borderRadius: "8px", marginBottom: "10px" }}
                  />
                  <h4>{item.name}</h4>
                  <p> {item.price ? `${item.price} Lek` : null}</p>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-between", // Space out buttons on mobile
                      width: "100%",
                    }}
                  >
                    <button
                      onClick={() => onModify(item)}
                      style={modifyButtonStyle}
                    >
                      Modifiko
                    </button>
                    <button
                      onClick={() => onDelete(item)}
                      style={deleteButtonStyle}
                    >
                      Fshije
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        /* Media query for desktop layout */
        @media (min-width: 768px) {
          .mobile-cards {
            display: none; /* Hide card layout on desktop */
          }
          .desktop-table {
            display: block; /* Show table layout on desktop */
          }
          .search-mobile {
            display: none; /* Hide search input on desktop */
          }
        }

        /* Media query for mobile layout */
        @media (max-width: 767px) {
          .desktop-table {
            display: none; /* Hide table layout on mobile */
          }
          .mobile-cards {
            display: block; /* Show card layout on mobile */
          }
        }
      `}</style>
    </div>
  );
};

const tableHeaderStyle = {
  border: "1px solid #ccc",
  padding: "10px",
};

const tableCellStyle = {
  border: "1px solid #ccc",
  padding: "10px",
};

const modifyButtonStyle = {
  marginRight: "5px",
  padding: "5px",
  background: "#ffc107",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

const deleteButtonStyle = {
  padding: "5px",
  background: "#dc3545",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

export { DataTableProducts };
